import Button from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import { getAssetSrc } from '@frontend/jetlend-web-ui/src/utils/getAssetSrc';
import arrowLeft from '@frontend/jetlend-assets/icons/icon--arrow-left--circle.svg';
import Link from 'next/link';
import styles from './FormBackLink.module.scss';

type BLProps = {
    onClick?: VoidFunction;
    href?: string;
}
const FormBackLink: React.FC<BLProps> = props => {
    const {
        onClick,
        href,
    } = props;

    const content = (
        <>
            <img src={getAssetSrc(arrowLeft)} alt="" className={styles['back__icon']} />
            <span className={styles['back__text']}>Назад</span>
        </>
    );

    if (href) {
        return (
            <Link href={href} className={styles['link']}>{content}</Link>
        );
    }

    return (
        <Button
            noSpacing
            onClick={onClick}
            type="plain"
            className={styles['back']}
            textClassName={styles['container']}
        >
            {content}
        </Button>
    );
};

export default FormBackLink;