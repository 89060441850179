import moment from 'moment';
import {
    DependencyList,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useOptionalInterval } from './useInterval';

/**
 * Хук, отсчитывающий определенное количество секунд до 0.
 *
 * Полезен при разработке подтверждающих сообщений.
 *
 * @param durationInSeconds Количество секунд, которые необходимо отсчитать
 * @param deps Зависимости, которые обновляют таймер
 * @returns
 * @category Hooks
 *
 * @see {@link useTickTimerV2}
 * @see {@link useRemainingSeconds}
 * @see {@link useTimeout}
 * @see {@link useOptionalInterval}
 * @see {@link useInterval}
 * @see {@link useOptionalInterval}
 *
 * @example
 * const remainingTime = useTickTimer(5);
 *
 * return (
 *      <Button
 *          text={withSeconds('Подтвердить', remainingTime)}
 *          disable={remainingTime > 0}
 *      />
 * );
 */
export function useTickTimer(durationInSeconds, deps?: DependencyList) {
    const targetTime = useMemo(
        () => moment().add(durationInSeconds, 'seconds'),
        deps
            ? [ durationInSeconds, ...deps ]
            : [ durationInSeconds ]
    );

    const [ now, setNow ] = useState(moment());
    const remainingSeconds = targetTime.diff(now, 'second');

    useOptionalInterval(() => setNow(moment()), remainingSeconds > 0, 125, []);

    return Math.max(0, remainingSeconds);
}

type HookReturnType = [
    number,
    () => void
];

/**
 * Хук, отсчитывающий определенное количество секунд до 0.
 *
 * Полезен при разработке подтверждающих сообщений.
 *
 * @param durationInSeconds Количество секунд, которые необходимо отсчитать
 * @param active Флаг, указывающий, когда таймер должен начать отсчет
 * @param deps Зависимости, которые обновляют таймер
 * @returns Оставшееся время в секундах и функцию для сброса отсчета на начало.
 * @category Hooks
 *
 * @see {@link useRemainingSeconds}
 * @see {@link useTimeout}
 * @see {@link useOptionalInterval}
 * @see {@link useInterval}
 * @see {@link useOptionalInterval}
 *
 * @example
 * const [
 *      // Оставшееся время
 *      remainingTime,
 *      // Функция для сброса таймера
 *      resetTimer
 * ] = useTickTimer(60, isOpen);
 *
 * // Перезапускаем таймер, после повторной отправки
 * const didResendClicked = useCallback(() => {
 *      sendSms();
 *      resetTimer();
 * }, [ resetTimer ]);
 *
 * return (
 *      <Button
 *          type="plain"
 *          icon={refreshIcon}
 *          text={withSeconds('Отправить код повторно', remainingTime)}
 *          disable={remainingTime > 0}
 *          onClick={didResendClicked}
 *      />
 * );
 */
export function useTickTimerV2(durationInSeconds, active: boolean, deps: DependencyList = []): HookReturnType {
    const [ remainingSeconds, setRemainingSeconds ] = useState(active ? durationInSeconds : 0);

    useEffect(() => {
        setRemainingSeconds(durationInSeconds);
    }, [ durationInSeconds, ...deps ]);

    const reset = useCallback(() => setRemainingSeconds(durationInSeconds), [ durationInSeconds ]);

    useOptionalInterval(
        () => setRemainingSeconds(v => Math.max(0, v - 1)),
        active && remainingSeconds > 0,
        1000
    );

    return [ remainingSeconds, reset ];
}