import React from 'react';

export interface ISmsSigned {
    sms_id: string;
}

export interface SmsConfirmFormValues extends ISmsSigned {
    code: string;
}

export enum ConfirmationMethod {
    Sms = 'sms',
    Push = 'push',
    Email = 'email',
}

export interface ISmsCodeApproveState {
    smsId?: any;
    /**
     * Номер телефона для верификации.
     */
    phone?: string;
    /**
     * Почта для верификации.
     */
    email?: string;
    /**
     * Тип источника, куда был отправлен код.
     */
    type?: ConfirmationMethod;
    loading: boolean;
    error?: string;
    isOpen: boolean;
    confirmationContent?: JSX.Element|React.ReactNode;
    descriptionContent?: JSX.Element|React.ReactNode;
    customTitle?: JSX.Element|React.ReactNode;
}